import React from "react";
import { Box, Typography } from "@mui/material";
import { motion } from "framer-motion";

const DecorationAvatar = ({ decorationUrl, avatarUrl, balance = 0 }) => {
  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: "5px",
      }}
    >
      <Box
        sx={{
          width: "100%",
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          position: "relative",
          ":before": {
            position: "absolute",
            width: "100%",
            height: "100%",
            content: "''",
            background: `url('${avatarUrl}')`,
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            backgroundSize: "70%",
            clipPath: "circle(35% at 50% 50%)",
            zIndex: -1,
          },
        }}
      >
        <img
          alt="Decoration"
          src={decorationUrl}
          style={{
            width: "100%",
          }}
        />
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          gap: "5px",
          img: {
            width: "22px",
          },
        }}
      >
        <img src="/images/bull-coin.png" alt="Coin" />
        <Typography
          sx={{
            color: "#FFFFFF",
            fontSize: "16px",
          }}
        >
          {new Intl.NumberFormat().format(balance)}
        </Typography>
      </Box>
    </Box>
  );
};

const Ranking = () => {
  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        padding: "20px 10px",
        gap: "20px",
      }}
    >
      <Box
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <motion.div
          initial={{ opacity: 0, y: "100%" }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
          style={{
            width: "50%",
          }}
        >
          <DecorationAvatar
            decorationUrl="./images/decoration/gold.png"
            avatarUrl="./images/avatars/bull1.png"
            balance={568527}
          />
        </motion.div>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
            gap: "20px",
          }}
        >
          <motion.div
            initial={{ x: "-100%" }}
            animate={{ x: 0 }}
            transition={{ duration: 0.5 }}
            style={{
              width: "40%",
            }}
          >
            <DecorationAvatar
              decorationUrl="./images/decoration/silver.png"
              avatarUrl="./images/avatars/bull2.png"
              balance={43324}
            />
          </motion.div>
          <motion.div
            initial={{ x: "100%" }}
            animate={{ x: 0 }}
            transition={{ duration: 0.5 }}
            style={{
              width: "40%",
            }}
          >
            <DecorationAvatar
              decorationUrl="./images/decoration/cooper.png"
              avatarUrl="./images/avatars/bull3.png"
              balance={2224}
            />
          </motion.div>
        </Box>
      </Box>
      <motion.div
        initial={{ y: "100%" }}
        animate={{ y: 0 }}
        transition={{ duration: 0.5 }}
        style={{
          flex: 1,
          width: "100%",
          minHeight: "180px",
          borderRadius: "16px",
          background:
            "linear-gradient(180deg, rgba(64,128,224,1) 0%, rgba(64,128,224,0.5) 100%)",
        }}
      ></motion.div>
    </Box>
  );
};

export default Ranking;
