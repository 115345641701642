import React from "react";
import { Box, Button, Grid, Typography } from "@mui/material";
import { motion } from "framer-motion";

import Status from "../components/status";

const REWARD_TYPE = {
  COIN: "coin",
  ENERGY: "energy",
};
const Reward = ({ day = 1, type = REWARD_TYPE.COIN, balance = 0 }) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        borderRadius: "10px",
        padding: "5px",
        gap: "5px",
        background:
          "linear-gradient(180deg, rgba(64,128,224,1) 0%, rgba(64,128,224,0.5) 100%)",
        ".claim-button": {
          width: "100%",
          minWidth: "32px",
          height: "28px",
          borderRadius: "8px",
          color: "#872E1C",
          background: "linear-gradient(180deg, #FAE9D5 0%, #FEC947 100%)",
          textTransform: "none",
          fontSize: "14px",
        },
      }}
    >
      <Typography
        sx={{
          color: "#FFFFFF",
          fontSize: "14px",
        }}
      >
        {day} Day
      </Typography>
      <Box
        sx={{
          height: "24px",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          gap: "2px",
          img: {
            width: "20px",
          },
        }}
      >
        <img
          src={
            type === REWARD_TYPE.COIN
              ? "/images/bull-coin.png"
              : "/images/energy.png"
          }
          alt="Reward"
        />
        <Typography
          sx={{
            color: "#FFFFFF",
            fontSize: "14px",
          }}
        >
          +{new Intl.NumberFormat().format(balance)}
        </Typography>
      </Box>
      <Button className="claim-button">Claim</Button>
    </Box>
  );
};

const Rewards = () => {
  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Status />
      <motion.div
        initial={{ y: "100%" }}
        animate={{ y: 0 }}
        transition={{ duration: 0.5 }}
        style={{
          flex: 1,
          padding: "10px 10px 20px",
          display: "flex",
          flexDirection: "column",
          gap: "10px",
        }}
      >
        <Grid container spacing={0.5}>
          <Grid item xs={2}>
            <Reward day={1} type={REWARD_TYPE.COIN} balance={100} />
          </Grid>
          <Grid item xs={2}>
            <Reward day={2} type={REWARD_TYPE.ENERGY} balance={200} />
          </Grid>
          <Grid item xs={2}>
            <Reward day={3} type={REWARD_TYPE.COIN} balance={300} />
          </Grid>
          <Grid item xs={2}>
            <Reward day={4} type={REWARD_TYPE.ENERGY} balance={400} />
          </Grid>
          <Grid item xs={2}>
            <Reward day={5} type={REWARD_TYPE.COIN} balance={500} />
          </Grid>
          <Grid item xs={2}>
            <Reward day={6} type={REWARD_TYPE.ENERGY} balance={600} />
          </Grid>
        </Grid>
        <Box
          sx={{
            flex: 1,
            width: "100%",
            minHeight: "180px",
            borderRadius: "16px",
            background:
              "linear-gradient(180deg, rgba(64,128,224,1) 0%, rgba(64,128,224,0.5) 100%)",
          }}
        ></Box>
      </motion.div>
    </Box>
  );
};

export default Rewards;
