import React from "react";
import { Box, Button } from "@mui/material";
import { motion } from "framer-motion";

import Status from "../components/status";

const Friends = () => {
  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        ".invite-button": {
          width: "100%",
          borderRadius: "10px",
          color: "#872E1C",
          background: "linear-gradient(180deg, #FAE9D5 0%, #FEC947 100%)",
          textTransform: "none",
          fontSize: "16px",
        },
      }}
    >
      <Status />
      <motion.div
        initial={{ y: "100%" }}
        animate={{ y: 0 }}
        transition={{ duration: 0.5 }}
        style={{
          flex: 1,
          display: "flex",
          flexDirection: "column",
          gap: "10px",
          width: "100%",
          padding: "10px 10px 20px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: "10px",
            padding: "20px 10px",
            borderRadius: "16px",
            background:
              "linear-gradient(180deg, rgba(64,128,224,1) 0%, rgba(64,128,224,0.5) 100%)",
          }}
        >
          <Button className="invite-button">Copy invite link</Button>
          <Button className="invite-button">Share invite link</Button>
        </Box>
        <Box
          sx={{
            flex: 1,
            width: "100%",
            minHeight: "180px",
            borderRadius: "16px",
            background:
              "linear-gradient(180deg, rgba(64,128,224,1) 0%, rgba(64,128,224,0.5) 100%)",
          }}
        ></Box>
      </motion.div>
    </Box>
  );
};

export default Friends;
