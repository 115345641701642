import { useEffect, useMemo, useState } from "react";
import { Box } from "@mui/material";
import { useIntegration } from "@telegram-apps/react-router-integration";
import { initNavigator } from "@telegram-apps/sdk-react";

import Background from "./components/background";
import MainTabs from "./components/mainTabs";
import UserInfo from "./components/userInfo";
import Home from "./pages/home";
import Friends from "./pages/friends";
import Ranking from "./pages/ranking";
import Rewards from "./pages/rewards";
import { Router, Route, Routes } from "react-router-dom";

function App() {
  const navigator = useMemo(() => initNavigator("app-navigation-state"), []);
  const [location, reactNavigator] = useIntegration(navigator);

  const [chatId, setChatId] = useState(
    window.Telegram.WebApp.initDataUnsafe?.user?.id
  );
  const [username, setUsername] = useState(
    window.Telegram.WebApp.initDataUnsafe?.user?.username
  );

  useEffect(() => {
    navigator.attach();
    return () => navigator.detach();
  }, [navigator]);

  useEffect(() => {}, []);

  return (
    <Box
      sx={{
        width: "100vw",
        height: "100vh",
        display: "flex",
        justifyContent: "center",
      }}
    >
      <Box
        sx={{
          position: "relative",
          maxWidth: "540px",
          height: "100vh",
          display: "flex",
          flexDirection: "column",
          overflow: "hidden",
        }}
      >
        <Router location={location} navigator={reactNavigator}>
          <Background />
          <UserInfo username={username} />
          <Box
            sx={{
              flex: 1,
              overflowX: "hidden",
              overflowY: "auto",
            }}
          >
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/friends" element={<Friends />} />
              <Route path="/ranking" element={<Ranking />} />
              <Route path="/rewards" element={<Rewards />} />
            </Routes>
          </Box>
          <MainTabs />
        </Router>
      </Box>
    </Box>
  );
}

export default App;
