//user info component
import React from "react";
import { Avatar, Box, Typography } from "@mui/material";

const UserInfo = ({ username }) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        gap: "10px",
        padding: "10px",
        borderBottom: "3px solid #4080E0",
      }}
    >
      <Avatar />
      <Typography
        sx={{
          color: "#FFFFFF",
          fontSize: "16px",
        }}
      >
        {username}
      </Typography>
    </Box>
  );
};

export default UserInfo;
