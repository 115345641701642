// mainTabs component
import React from "react";
import { Box, Typography, Button, Grid } from "@mui/material";
import { useState } from "react";
import { Link } from "react-router-dom";
const MainTabs = () => {
  const [value, setValue] = useState(0);

  return (
    <Box>
      <Grid
        container
        sx={{
          width: "100%",
          aspectRatio: "4",
          "a:-webkit-any-link": {
            textDecoration: "none",
          },
          ".tab-button": {
            height: "100%",
            paddingBottom: "15%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "end",
            gap: "10px",
            color: "#872E1C",
            fontWeight: "bold",
            fontSize: "14px",
            background: "linear-gradient(180deg, #FAE9D5 0%, #FEC947 100%)",
            borderTopLeftRadius: "15px",
            borderTopRightRadius: "15px",
            borderRight: "1px solid #203040",
            borderLeft: "1px solid #203040",
            img: {
              width: "40%",
            },
            ".MuiTypography-root": {
              fontSize: "14px",
            },
          },
        }}
      >
        <Grid item xs={3}>
          <Link to="/">
            <Button className="tab-button">
              <img alt="Home" src="/images/tab/home.png" />
              <Typography>Home</Typography>
            </Button>
          </Link>
        </Grid>
        <Grid item xs={3}>
          <Link to="/friends">
            <Button className="tab-button">
              <img alt="Friends" src="/images/tab/friends.png" />
              <Typography>Friends</Typography>
            </Button>
          </Link>
        </Grid>
        <Grid item xs={3}>
          <Link to="/ranking">
            <Button className="tab-button">
              <img alt="Ranking" src="/images/tab/ranking.png" />
              <Typography>Ranking</Typography>
            </Button>
          </Link>
        </Grid>
        <Grid item xs={3}>
          <Link to="/rewards">
            <Button className="tab-button">
              <img alt="Rewards" src="/images/tab/rewards.png" />
              <Typography>Rewards</Typography>
            </Button>
          </Link>
        </Grid>
      </Grid>
    </Box>
  );
};

export default MainTabs;
