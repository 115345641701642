import React from "react";
import { Box } from "@mui/material";

const Background = () => {
  return (
    <Box
      sx={{
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        zIndex: -1,
      }}
    >
      <Box
        sx={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          background: "#000000",
          backgroundSize: "cover",
        }}
      />
      <Box
        sx={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          background:
            "linear-gradient(180deg, rgba(16,59,123,0.1) 0%, rgba(53,114,245,0.2) 25%, rgba(0,70,174,0.4) 100%)",
          backgroundSize: "cover",
        }}
      />
      <Box
        sx={{
          position: "absolute",
          bottom: "-10%",
          left: "-15%",
          width: "130%",
          aspectRatio: "1.5",
          background:
            "linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(64,128,224,0.5) 100%)",
          backgroundSize: "cover",
          clipPath: "ellipse(50% 50% at 50% 50%)",
        }}
      />
    </Box>
  );
};

export default Background;
