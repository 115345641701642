import React, { useState } from "react";
import { Box } from "@mui/material";
import { motion } from "framer-motion";

import Status from "../components/status";

const Home = () => {
  const [clickPositions, setClickPositions] = useState([]);
  const [imageAnimation, setImageAnimation] = useState(false);

  const handleClick = (event) => {
    const rect = event.currentTarget.getBoundingClientRect();
    const x = event.clientX - rect.left;
    const y = event.clientY - rect.top;

    setClickPositions((prevPositions) => [
      ...prevPositions,
      { x, y, id: Date.now() }, // Add a unique ID for each click
    ]);

    // Trigger image animation
    setImageAnimation(true);
  };

  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Status />
      <motion.div
        initial={{ y: "100%" }}
        animate={{ y: 0 }}
        transition={{ duration: 0.5 }}
        style={{
          width: "100%",
          flex: 1,
        }}
      >
        <Box
          onClick={handleClick}
          sx={{
            width: "100%",
            height: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            padding: "20px",
            position: "relative",
          }}
        >
          <motion.img
            alt="Bull"
            src="./images/bulls/bull1.png"
            style={{ width: "80%", userSelect: "none" }}
            animate={imageAnimation ? { scale: 1.05 } : { scale: 1 }}
            transition={{ duration: 0.05 }}
            onAnimationComplete={() => setImageAnimation(false)}
          />

          {clickPositions.map(({ x, y, id }) => (
            <motion.div
              key={id} // Use the unique ID as the key
              initial={{ opacity: 1, x: "-50%", y: 0 }}
              animate={{ opacity: 0, x: "-50%", y: -90 }}
              transition={{ duration: 2 }}
              style={{
                position: "absolute",
                left: x,
                top: y,
                pointerEvents: "none",
                color: "#FFFFFF",
                fontSize: "24px",
                fontWeight: "bold",
              }}
              onAnimationComplete={() =>
                setClickPositions((prevPositions) =>
                  prevPositions.filter((position) => position.id !== id)
                )
              }
            >
              +1
            </motion.div>
          ))}
        </Box>
      </motion.div>
    </Box>
  );
};

export default Home;
