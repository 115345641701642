import React from "react";
import { Box, Grid, Typography } from "@mui/material";
import { motion } from "framer-motion";

const Status = () => {
  return (
    <motion.div
      initial={{ y: "-100%" }}
      animate={{ y: 0 }}
      transition={{ duration: 0.5 }}
      style={{ padding: "10px" }}
    >
      <Grid
        container
        spacing={1}
        sx={{
          ".earn-container": {
            height: "80px",
            borderRadius: "16px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "space-between",
            paddingTop: "10px",
            paddingBottom: "10px",
            background:
              "linear-gradient(180deg, rgba(64,128,224,1) 0%, rgba(64,128,224,0.5) 100%)",
          },
        }}
      >
        <Grid item xs={4}>
          <Box className="earn-container">
            <Typography
              sx={{
                color: "#FFFFFF",
                fontSize: "14px",
              }}
            >
              Earn per tap
            </Typography>
            <Box
              sx={{
                height: "32px",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                gap: "10px",
                img: {
                  width: "24px",
                },
              }}
            >
              <img src="/images/bull-coin.png" alt="Coin" />
              <Typography
                sx={{
                  color: "#FFFFFF",
                  fontSize: "24px",
                }}
              >
                +1
              </Typography>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={4}>
          <Box className="earn-container">
            <Typography
              sx={{
                color: "#F0E050",
                fontSize: "14px",
              }}
            >
              Earn per hour
            </Typography>
            <Box
              sx={{
                height: "32px",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                gap: "10px",
                img: {
                  width: "24px",
                },
              }}
            >
              <img src="/images/bull-coin.png" alt="Coin" />
              <Typography
                sx={{
                  color: "#FFFFFF",
                  fontSize: "18px",
                }}
              >
                +0
              </Typography>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={4}>
          <Box className="earn-container">
            <Typography
              sx={{
                color: "#50E060",
                fontSize: "14px",
              }}
            >
              Energy
            </Typography>
            <Box
              sx={{
                height: "32px",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                gap: "5px",
                img: {
                  width: "24px",
                },
              }}
            >
              <img src="/images/energy.png" alt="Energy" />
              <Typography
                sx={{
                  color: "#FFFFFF",
                  fontSize: "14px",
                }}
              >
                1,000/1,000
              </Typography>
            </Box>
          </Box>
        </Grid>
      </Grid>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          paddingTop: "20px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: "10px",
            img: {
              width: "54px",
            },
          }}
        >
          <img src="/images/bull-coin.png" alt="Coin" />
          <Typography
            sx={{
              color: "#FFFFFF",
              fontSize: "32px",
            }}
          >
            0
          </Typography>
        </Box>
        <Box
          sx={{
            marginTop: "15px",
            width: "100%",
            height: "18px",
            border: "3px solid #4080E0",
            borderRadius: "9px",
          }}
        >
          <Box
            sx={{
              width: "50%",
              height: "12px",
              borderRadius: "9px",
              background:
                "linear-gradient(180deg, rgba(64,128,224,1) 0%, rgba(64,128,224,0.5) 100%)",
            }}
          />
        </Box>
        <Box
          sx={{
            marginTop: "5px",
            width: "100%",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Typography sx={{ color: "#FFFFFF", fontSize: "14px" }}>
            Level 0
          </Typography>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              gap: "5px",
              img: {
                width: "20px",
              },
            }}
          >
            <img src="/images/bull-coin.png" alt="Coin" />
            <Typography
              sx={{
                color: "#FFFFFF",
                fontSize: "14px",
              }}
            >
              25,000
            </Typography>
          </Box>
        </Box>
      </Box>
    </motion.div>
  );
};

export default Status;
